@font-face {
	font-family: 'regular';
	src: url('assets/font/opensans/OpenSans-Regular-webfont.eot');
	src: url('assets/font/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
			url('assets/font/opensans/OpenSans-Regular-webfont.woff') format('woff'),
			url('assets/font/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
			url('assets/font/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'italic';
	src: url('assets/font/opensans/OpenSans-Italic-webfont.eot');
	src: url('assets/font/opensans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
			url('assets/font/opensans/OpenSans-Italic-webfont.woff') format('woff'),
			url('assets/font/opensans/OpenSans-Italic-webfont.ttf') format('truetype'),
			url('assets/font/opensans/OpenSans-Italic-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'bold';
	src: url('assets/font/opensans/OpenSans-Bold-webfont.eot');
	src: url('assets/font/opensans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
			url('assets/font/opensans/OpenSans-Bold-webfont.woff') format('woff'),
			url('assets/font/opensans/OpenSans-Bold-webfont.ttf') format('truetype'),
			url('assets/font/opensans/OpenSans-Bold-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

* {
	font-family: 'regular';
	margin: 0;
	padding: 0;
	border: none;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	list-style: none;
	outline: none;
	color: var(--color-font);
}

body, html {
	overflow-x: hidden;
}

body {
	background: var(--color-background);
	padding: 0 0 80px 0;
	border-top: solid 60px var(--color-white);
}

strong, b {
	font-family: 'bold';
}

em, i {
	font-family: 'italic';
}

.wrp {
	box-sizing: border-box;
	margin: auto 10px;
	position: relative;
}

main {
	padding: 0 0 20px 0;
}

p {
	margin-bottom: 15px;
}

@keyframes shimmer {
	0% {
			background-position: top left;
	}
	100% {
			background-position: top right;
	}
}

h1, h2, h3 {
	position: relative;
	font-size: 22px;
	color: rgba(255, 255, 255, 0.1);
	background: gradient(linear, left top, right top, from(var(--color-gold)), to(var(--color-gold)), color-stop(0.1, lighten(#B9A063, 20%)));
	background: -webkit-gradient(linear, left top, right top, from(var(--color-gold)), to(var(--color-gold)), color-stop(0.1, lighten(#B9A063, 20%)));
	animation-name: shimmer;
	animation-iteration-count: infinite;
	animation-duration: 6s;
	background-clip: text;
	background-color: var(--color-gold);
	-webkit-background-clip: text;
	-webkit-background-size: 125px 100%;
	background-size: 125px 100%;
	background-repeat: no-repeat;
	background-position: 0 0;

	&::after {
		content: "";
		display: block;
		width: 80px;
		margin-top: 10px;
		height: 3px;
		border-radius: 8px 0 8px 0;
		background: var(--color-green);
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 1440px) {

	main {
		padding-bottom: 40px;
	}

	.wrp {
		max-width: 1440px;
		margin: auto;
	}

	p {
		margin-bottom: 20px;
	}

	h1,h2, h3 {
		font-size: 32px;
	}
}

@media screen and (min-width: 1920px) {

	main {
		padding-bottom: 80px;
	}
}
