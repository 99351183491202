.tierchiropraktik,
.tierphysiotherapie {
	.wrp {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}

	img {
		align-self: flex-start;
		display: block;
		width: 100%;
		border-radius: var(--border-radius);
		box-shadow: 0 0 6px rgba(0,0,0,0.2);
		height: auto;
		max-width: 320px;
		margin: auto;
	}

	article {
		background: var(--color-white);
		padding: 10px;
		border-radius: var(--border-radius);
		margin-bottom: 20px;
	}

	.columns {
		padding-top: 10px;
		display: flex;
		gap: 40px;
		flex-direction: column;

		ul {
			max-width: 420px;
			display: block;

			li {
				display: flex;
				flex-direction: row;
				align-items: start;
				margin-bottom: 2px;

				&:not(:first-child)::before {
					align-self: start;
					flex: 0 0 auto;
					display: block;
					content: '';
					border-radius: 8px 0 8px 0;
					width: 16px;
					height: 10px;
					margin-right: 8px;
					margin-top: 8px;
					background: var(--color-gold);
				}

				strong {
					display: block;
					background: var(--color-background);
					padding: 5px 24px;
					box-sizing: border-box;
					width: 100%;
				}
			}
		}
	}

@media screen and (min-width: 920px) {
	.wrp {
		flex-direction: row;
		gap: 40px;
		margin-bottom: 40px;
	}

	img {
		margin: 0;
		min-width: 40%;
	}

	article {
		align-self: start;
		padding: 20px;
	}

	.columns {
		padding-top: 20px;
		flex-direction: row;
		justify-content: space-between;
		align-items: start;

		ul {
			width: 50%;
			max-width: unset;

			li {
				margin-bottom: 5px;
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	&.tierchiropraktik {
		padding-top: 40px;
	}

	.wrp {
		margin-bottom: 80px;
		article {
			padding: 40px;
			&:first-child {
				//margin-left: 160px;

				&::before {
					content: '';
					background: url(assets/graphic_paw.svg) no-repeat center center;
					width: 60px;
					height: 60px;
					position: absolute;
					left: 170px;
					top: -20px;
					transform: rotate(-25deg);
					display: block;
				}
			}

			&:last-child {
				//margin-right: 160px;

				&::after {
					content: '';
					background: url(assets/graphic_paw.svg) no-repeat center center;
					width: 60px;
					height: 60px;
					position: absolute;
					right: 170px;
					top: -20px;
					transform: rotate(25deg);
					display: block;
				}
			}
		}
	}

	.columns {
		padding-top: 40px;
	}
}

}
