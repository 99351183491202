.page-form {
	.wrp {
		border-radius: var(--border-radius);
		background: var(--color-white);
		padding: 10px;
		box-sizing: border-box;
		max-width: 620px;
		margin: 20px auto;
	}

	.contact {
		margin: 0;

		form {
			padding-top: 20px;
		}
	}


	@media screen and (min-width: 1440px) {
		.wrp {
			padding: 20px;
			margin: 40px auto;
		}

		.contact {
			margin: 0;

			form {
				padding-top: 40px;
			}
		}
	}

	@media screen and (min-width: 1440px) {
		.wrp {
			padding: 40px;
			max-width: 800px;
			margin: 80px auto;
		}

		.contact {
			margin: 0;

			form {
				padding-top: 60px;
			}
		}
	}
}
