:root {
	--color-background: #F1EEED;
	--color-white: #fff;
	--color-font: #222;
	--color-green: #811234;
	--color-gold: #B9A063;
	--color-gold-hover: #8d7947;
	--color-red: #811234;
	--border-radius: 8px;
}
