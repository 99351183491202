.gallery {
	margin: 40px 0;
	width: 100%;
	overflow: auto;

	.wrp {
		width: calc(400% - 200px);
		display: flex;
		padding: 10px 0;
		gap: 10px;
		justify-content: space-between;

		a {
			flex: 1 1 0px;
			width:10%;
			height: auto;
			display: block;

			img {
				border-radius: var(--border-radius);
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	@media screen and (min-width: 920px) {
		margin: 60px 0;

		.wrp {
			padding: 0;
			width: 150%;
		}
	}

	@media screen and (min-width: 1440px) {
		margin: 100px 0;

		.wrp {
			width: 100%;
		}
	}
}
