.introduction {
	margin-bottom: 20px;

	img {
		display: block;
		width: 100%;
		height: auto;
		position: relative;
		align-self: flex-start;
		border-radius: var(--border-radius);
		max-width: 520px;
		margin: auto auto 20px auto;
	}

	@media screen and (min-width: 920px) {
		margin-bottom: 40px;

		.wrp {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: start;
			gap: 40px;
		}

		img {
			min-width: 40%;
			top: -80px;
			margin: 0;
		}
	}

	@media screen and (min-width: 1440px) {
		padding-top: 100px;
		position: relative;
		top: -100px;
		margin-bottom: 0;

		img {
			box-shadow: 0 -20px 12px rgba(0,0,0,.2);
		}
	}
}
