.praxis {

	.info {
		display: block;
		padding: 10px;
		background: var(--color-background);
		border-radius: 4px;

		p {
			margin: 0;
		}
	}

	img {
		border-radius: var(--border-radius);
	}

	.btn {
		display: inline-block;
		background: var(--color-green);
		color: var(--color-white);
		padding: 10px 25px;
		border-radius: 120px;
		margin-top: 20px;
		white-space: nowrap;
		transition: background 250ms ease;
		cursor: pointer;

		&:hover {
			background: var(--color-gold);
			transition: background 250ms ease;
		}
	}

	.contact-information {
		padding: 10px;
		background: var(--color-white);
		border-radius: var(--border-radius);
		margin-bottom: 20px;
	}

	.columns {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	@media screen and (min-width: 920px) {
		.contact-information {
			padding: 20px;
			position: relative;

			&::after {
				content: '';
				background: url(assets/graphic_paw.svg) no-repeat center center;
				width: 60px;
				height: 60px;
				position: absolute;
				right: -20px;
				top: -20px;
				transform: rotate(25deg);
				display: block;
			}
		}

		.wrp {
			display: flex;
			flex-direction: row;
			gap: 80px;
			justify-content: space-evenly;
		}

		.col {
			width: 50%;
		}

		.columns {
			flex-direction: row;
			justify-content: space-evenly;
			align-items: start;
			margin-bottom: 20px;
		}
	}

	@media screen and (min-width: 1440px) {
		.contact-information {
			padding: 40px;
		}

		article {
			min-width: 60%;
		}
	}
}
