.cards {

	.wrp {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	article {
		background: var(--color-white);
		border-radius: var(--border-radius);
		padding: 10px;

		img {
			border-radius: var(--border-radius);
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 10px;
		}
	}

	@media screen and (min-width: 920px) {
		.wrp {
			flex-direction: row;
			justify-content: space-evenly;
		}

		article {
			box-sizing: border-box;
			width: 30%;

		}

	}

	@media screen and (min-width: 1440px) {
		.wrp {
			gap: 20px;
		}

		article {
			padding: 20px;

			img {
				margin-bottom: 20px;
			}
		}

	}
}
