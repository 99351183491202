.leistungen {

	.wrp {
		display: flex;
		flex-direction: column;
		gap: 20px;
		> article:first-of-type {
			background: var(--color-white);
			padding: 10px;
			border-radius: var(--border-radius);

		}
	}

	article {
		align-self: start;

		li {
			display: flex;
			flex-direction: row;
			align-items: start;
			margin-bottom: 2px;

			&::before {
				align-self: start;
				flex: 0 0 auto;
				display: block;
				content: '';
				border-radius: 8px 0 8px 0;
				width: 16px;
				height: 10px;
				margin-right: 8px;
				margin-top: 8px;
				background: var(--color-gold);
			}
		}
	}


	@media screen and (min-width: 920px) {
		.wrp {
			flex-direction: row;
			gap: 40px;

			> article:first-of-type {
				padding: 20px;
			}
		}

		article {
			li {
				margin-bottom: 5px;
			}
		}

	}

	@media screen and (min-width: 1440px) {
		.wrp {
			gap: 80px;

			> article:first-of-type {
				padding: 40px;
			}
		}


	}
}
