nav.main {

	.wrp {
		position: fixed;
		z-index: 2;
		left: 0;
		top: 5px;
		right: 0;
		padding: 5px;
		box-sizing: border-box;
		height: 50px;
		background: rgba(255,255,255,0.85);
		backdrop-filter: blur(2px);
		border-radius: 200px;
		display: flex;
		align-items: center;
	}

	.brand-logo {
		display: inline-block;
		height: 40px;
		width: auto;

		img {
			display: inline-block;
			height: 100%;
			width: auto;
			max-width: 280px
		}
	}

	@keyframes offCanvasIconSpanLast {
		0% {
			top: 30px;
			transform: rotate(0);
		}
		50% {
			top: 20px;
			transform: rotate(0);
		}
		100% {
			top: 20px;
			transform: rotate(45deg);
		}
	}

	@keyframes offCanvasIconSpanFirst {
		0% {
			top: 10px;
			transform: rotate(0);
		}
		50% {
			top: 20px;
			transform: rotate(0);
		}
		100% {
			top: 20px;
			transform: rotate(-45deg);
		}
	}

	.offcanvas-icon {
		position: absolute;
		right: 20px;
		top: 3px;
		width: 40px;
		height: 40px;

		span {
			background: var(--color-font);
			height: 3px;
			border-radius: 200px;
			width: 30px;
			left: 5px;
			position: absolute;
			transition: all 500ms ease;

			&:nth-child(1) {
				top: 10px;
				transform: rotate(0deg);
				transition: all 500ms ease;
			}

			&:nth-child(2) {
				top: 20px;
				transition: opacity ease 0.2s;
			}

			&:nth-child(3) {
				top: 30px;
				transition: all 500ms ease;
			}
		}

		&.active {
			span:nth-child(1) {
				animation-name: offCanvasIconSpanFirst;
				animation-iteration-count: 1;
				animation-direction: normal;
				animation-duration: 500ms;
				animation-fill-mode: forwards;
				transition: top ease 0.2s 500ms;
			}

			span:nth-child(2) {
				opacity: 0;
				transition: opacity ease 0.2s;
			}

			span:nth-child(3) {
				animation-name: offCanvasIconSpanLast;
				animation-iteration-count: 1;
				animation-direction: normal;
				animation-duration: 500ms;
				animation-fill-mode: forwards;
				transition: top ease 0.2s 500ms;
			}
		}
	}

	ul {
		position: fixed;
		z-index: 1;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		left: 0;
		top: -100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 40px;
		background: rgba(0,0,0,0.85);
		backdrop-filter: blur(2px);
		transition: top 200ms ease-in;

		&.active {
			top: 0;
			transition: top 200ms ease-out;
		}

		a {
			color: var(--color-white);
			font-size: 22px;
		}

		li {
			&:last-of-type {
				a {
					color: var(--color-white);
					background: var(--color-green);
					padding: 5px 35px;
					border-radius: 200px;
				}
			}
		}
	}

	@media screen and (min-width: 1440px) {
		position: fixed;
		z-index: 2;
		left: 0;
		top: 5px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1440px;
		border-radius: 200px;
		margin: auto;
		height: 50px;
		background: rgba(255,255,255,0.85);
		box-shadow: 0 0 12px rgba(0,0,0,.2);
		backdrop-filter: blur(2px);
		padding: 5px 8px;
		box-sizing: border-box;

		.wrp {
			width: 220px;
			margin: 0;
			flex: 0 0 220px;
			position: static;
			display: block;
			position: relative;
			padding: 0;
			background: none;
			border-radius: 0;
			backdrop-filter: none;
		}

		.offcanvas-icon {
			display: none;
		}

		ul {
			position: static;
			backdrop-filter: none;
			width: auto;
			background: none;
			height: auto;
			top: 20px;
			flex-direction: row;

			a {
				color: var(--color-font);
				font-size: 18px;
				transition: color 250ms ease;

				&:hover {
					color: var(--color-gold);
					transition: color 250ms ease;
				}
			}


			li {
				&:last-of-type {
					a {
						transition: color 250ms ease, background 250ms ease;
						&:hover {
							background: var(--color-gold);
							color: var(--color-white);
							transition: color 250ms ease, background 250ms ease;
						}

					}
				}
			}
		}
	}
}
