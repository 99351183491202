.pricing {
	margin: 40px 0;

	.wrp {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	article {
		background: var(--color-white);
		padding: 10px;
		border-radius: var(--border-radius);

	}

	.btn {
		background: var(--color-green);
		padding: 10px 25px;
		border-radius: 120px;
		color: var(--color-white);
		text-align: center;
		align-self: center;
		transition: background 250ms ease;
		cursor: pointer;

		&:hover {
			background: var(--color-gold);
			transition: background 250ms ease;
		}
	}

	ul {
		li {
			display: block;
			margin-bottom: 2px;
			padding-left: 24px;
			position: relative;

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				content: '';
				border-radius: 8px 0 8px 0;
				width: 16px;
				height: 10px;
				margin-top: 8px;
				background: var(--color-gold);
			}

			ul {
				display: block;
			}

			.price, strong {
				font-family: 'regular';
				float: right;
				color: var(--color-gold)
			}

			.price-info, em {
				font-size: 12px;
			}
		}
	}

	.col > ul:first-of-type > li {
		&:last-child {
			padding-left: 0;
			color: var(--color-red);
			font-family: 'italic';

			em {
				color: var(--color-red);
			}

			&::before {
				display: none;
			}
		}
	}

	.columns {
		padding-top: 10px;
		display: flex;
		gap: 40px;
		flex-direction: column;

		.col {
			flex: 1 1 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.content {
			margin-bottom: 30px;
		}
	}

	@media screen and (min-width: 920px) {
		article {
			padding: 20px;
		}

		.wrp {
			flex-direction: row-reverse;
			gap: 40px;
			margin-bottom: 40px;
		}

		.btn {
			align-self: end;
			justify-self: center;
		}

		ul {
			li {
				margin-bottom: 5px;
				padding-bottom: 5px;
				border-bottom: dotted 2px var(--color-background);

				&:hover {
					&::before {
						background: var(--color-green);
					}

					> .price,
					> strong {
						color: var(--color-green);
					}
				}
			}
		}

		.columns {
			padding-top: 20px;
			gap: 80px;
			flex-direction: row;
			justify-content: space-between;

			.col {
				min-width: calc(50% - 40px);
			}
		}
	}

	@media screen and (min-width: 1440px) {
		article {
			padding: 40px;
		}
	}
}
