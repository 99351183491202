.banner {
	background: #f0f0f0;
	padding: 10px 0;

	.wrp {
		padding: 10px;
		background: lighten(#222, 10%);
		border-radius: var(--border-radius);
		max-width: 1020px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		opacity: 0;
		animation: text 500ms 600ms ease-out forwards;

		p {
			color: var(--color-white);
			font-size: 14px;
			margin: 0;
		}

		@keyframes text {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		a {
			font-size: 14px;
			display: block;
			padding: 5px 10px;
			text-align: center;
			max-width: 230px;
			min-width: 180px;
			color: var(--color-white);
			border-radius: 120px;
			border: solid 2px var(--color-white);
			margin: 10px auto;
			transition: color 250ms ease, background 250ms ease;

			&:hover {
				background: var(--color-white);
				color: var(--color-font);
				transition: color 250ms ease, background 250ms ease;
			}
		}
	}

	@media screen and (min-width: 920px) {
		.wrp {
			gap: 20px;
			flex-direction: row;
		}
	}

	@media screen and (min-width: 1440px) {
		.wrp {
			gap: 40px;
		}
	}
}
