footer.main {
	padding: 20px 10px;

	img {
		max-width: 280px;
		margin-bottom: 20px;
	}

	ul {

		li {
			display: block;
			border-bottom: solid 1px rgba(0,0,0,0.25);
		}
		a {
			display: block;
			padding: 5px 0;
			margin: 5px 0 4px 0;
			transition: all 250ms ease;
			border-bottom: solid 1px transparent;

			&:hover {
				border-bottom: solid 1px var(--color-green);
				transition: all 250ms ease;
			}
		}
	}

	@media screen and (min-width: 920px) {
		padding: 80px 0 0 0;

		nav {
			display: flex;
			gap: 40px;
			flex-direction: row;
			justify-content: space-between;
		}

		ul {
			li {
				border: none;
			}

			a {
				margin: 10px 0;
			}
		}
	}
}
