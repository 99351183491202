.slider-wrapper {
	img {
		display: block;
		width: 100%;
		height: auto;
	}

	.tns-nav {
		display: flex;
		justify-content: center;
		flex-direction: row;
		gap: 10px;
		padding: 20px 0;
	}

	button {
		display: block;
		height: 10px;
		width: 30px;
		border-radius: 120px;
		background: var(--color-font);

		&.tns-nav-active {
			background: var(--color-gold);
		}
	}

}
