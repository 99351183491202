.contact {
	margin: 40px auto;

	.wpcf7-response-output {
		display: block;
		font-family: 'bold';
		color: var(--color-green);
		padding: 10px 0;
	}

	.wrp {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.screen-reader-response {
		p {
			margin: 0;
		}
	}

	address {
		background: var(--color-white);
		border-radius: var(--border-radius);
		padding: 10px;

		img {
			max-width: 280px;
			display: block;
			margin-bottom: 20px;
		}

		> a {
			border-bottom: dotted 2px var(--color-background);
		}
	}

	.certifications {
		p {
			margin-top: 20px;
			display: flex;
			flex-direction: rop;
			gap: 20px;
		}

		img {
			display: block;
			width: 80px;
			height: 80px;
		}
	}

	.contact-form {
		background: var(--color-white);
		border-radius: var(--border-radius);
		padding: 10px;

		p {
			margin: 0;
		}
	}

	label {
		display: block;
		margin-bottom: 10px;
	}

	input,
	textarea {
		margin-top: 10px;
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding: 10px;
		background: var(--color-background);
		border: solid 2px var(--color-background);
		border-radius: var(--border-radius);
		color: var(--color-font);
		transition: all 200ms ease;
		font-family: 'regular';
		font-size: 18px;

		&:hover {
			border: solid 2px var(--color-font);
			transition: all 200ms ease;
		}

		&:focus {
			border: solid 2px var(--color-gold);
			color: var(--color-gold);
			font-size: 18px;
			transition: all 200ms ease;
		}


		&[type="submit"] {
			background: var(--color-green);
			color: var(--color-white);
			transition: all 250ms ease;
			cursor: pointer;
			border: solid 2px var(--color-green);

			&:hover {
				background: var(--color-gold);
				transition: all 250ms ease;
				border: solid 2px var(--color-gold);
			}
		}
	}

	textarea {
		min-height: 240px;
	}

	@media screen and (min-width: 920px) {

		.certifications {
			p {
				margin-top: 20px;
				display: flex;
				flex-direction: rop;
				gap: 20px;
			}

			img {
				display: block;
				width: 120px;
				height: 120px;
			}
		}

		.wrp {
			flex-direction: row;
			align-items: start;
			gap: 40px;
		}

		.contact-form, address {
			padding: 20px;
		}

		.contact-form {
			flex: 3 0 0;
		}
	}

	@media screen and (min-width: 1440px) {
		margin: 80px auto;

		.wrp {
			flex-direction: row;
			gap: 80px;
		}

		.contact-form, address {
			padding: 40px;
		}

		.certifications {
			margin-top: 40px;
		}

	}
}
