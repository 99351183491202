.accordion {

	article {
		background: var(--color-white);
		border-radius: var(--border-radius);
		//border: solid 1px #ccc;
		margin-bottom: 10px;

		header {
			color: var(--color-gold);
			font-size: 22px;
			padding: 10px;
			border-bottom: solid 0px var(--color-background);
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			transition: border 200ms ease-out;

			.toggle {
				display: inline-block;
				font-size: 22px;
				color: var(--color-gold);
				font-family: 'bold';
				transition: transform 200ms ease-out;
			}
		}

		.content {
			padding: 0 10px;
			overflow: hidden;
			transition: height 200ms ease-out;
		}

		&.active {
			header {
				border-bottom: solid 2px var(--color-background);
				transition: border 200ms ease-out;

				.toggle {
					transform: rotate(45deg);
					transition: transform 200ms ease-out;
				}
			}
			.content {
				padding: 10px;
				transition: height 200ms ease-out;
			}
		}
	}

	@media screen and (min-width: 920px) {
		article {
			header {
				padding: 10px 20px;
			}

			.content {
				padding: 0 20px;
			}

			&.active {
				.content {
					padding: 20px;
				}
			}
		}
	}

	@media screen and (min-width: 1440px) {
		article {
			header {
				padding: 20px 40px;
			}

			.content {
				padding: 0 40px;
			}

			&.active {
				.content {
					padding: 20px 40px;
				}
			}
		}
	}

}
